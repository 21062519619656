import React, { useEffect, useState } from "react";
import Receipt from "../images/receipt.png";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { getAuth, signInAnonymously } from "firebase/auth";
import axios from "axios";

export default function CancelModal() {
  const [showModal, setShowModal] = useState(false);
  const [reservationId, setReservationId] = useState("");
  const [loading, setLoading] = useState("");
  const [reservation, setReservation] = useState();
  const [paymentIntentId, setPaymentIntentId] = useState();
  const [error, setError] = useState();
  const [cancelled, setCancelled] = useState(true);

  function isMoreThan24HoursBefore(startDateStr) {
    // Parse the startDate string into a Date object
    const startDate = new Date(startDateStr);

    // Get the current date and time in UTC
    const now = new Date();

    // Calculate the difference in milliseconds between the startDate and now
    const timeDifference = startDate.getTime() - now.getTime();

    // Return true if the current time is more than 24 hours before the startDate
    return timeDifference > 24 * 60 * 60 * 1000;
  }

  const getReservation = () => {
    const url = `https://fetchtwiceorder-igv4fwihoq-uc.a.run.app?id=${reservationId}`;

    setLoading("Finding your reservation");
    setError("");
    axios
      .get(url)
      .then((response) => {
  
        setReservation(response.data);
        setLoading("");
        setError("");
        if (isMoreThan24HoursBefore(response.data.startDate)) {
          LocatePaymentIntent();
        } else {
          setError(
            "The start time for this reservation is less than 24 hours from now and cannot be cancelled"
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching order details:", error);
        setLoading("");
        setError(
          "Unable to locate your reservation. Please ensure your reservation id is correct"
        );
      });
  };

  const LocatePaymentIntent = () => {
    const url = `https://locatepaymentintent-igv4fwihoq-uc.a.run.app?orderId=${reservationId}`;

    setLoading("Locating your payment information");
    setError("");
    axios
      .get(url)
      .then((data) => {

        setPaymentIntentId(data.data[0].id);
        setLoading("");
        setError("");
      })
      .catch((error) => {
        console.error("Error fetching payment details:", error);
        setLoading("");
        setError(
          "Unable to locate your payment details. Please contact us at info@oharaslanding.com or call (860) 824-7583 to cancel your reservation"
        );
      });
  };

  const IssueRefund = () => {
    const url = `https://us-central1-oharas-landing-a7318.cloudfunctions.net/issueStripeRefund?payment_intent=${paymentIntentId}`;

    setLoading("Issuing refund");
    setError("");

    const response = axios
      .post(url, `payment_intent=${paymentIntentId}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((data) => {
   
        setLoading("");
        setError("");
        CancelReservation();
      })
      .catch((error) => {
        if (
          error.response.data.error.message.error.code ===
          "charge_already_refunded"
        ) {
          setLoading("");
          CancelReservation();
        } else {
          console.error("Error issuing refund", error);
          setLoading("");
          setError(
            "There was a problem. Please contact us at info@oharaslanding.com or call (860) 824-7583 to cancel your reservation"
          );
        }
      });
  };

  const SendEmails = () => {
    const url = `https://sendconfirmationemails-igv4fwihoq-uc.a.run.app/?id=${reservation.id}&pi=${paymentIntentId}&recipientAddress=${reservation.customerDetails.email}&firstName=${reservation.customerDetails.firstName}&lastName=${reservation.customerDetails.lastName}`;
    setLoading("Sending Confirmation Email");
    setError("");
    fetch(url)
      .then((data) => {
        setLoading("");
        setError("");
        setCancelled(true);
      })
      .catch((error) => {
        console.error("Error sending confirmation emails", error);
        setLoading("");
        setError(
          "We were unable to send your confirmation email but your cancellation has been processed and your refund issued. If you do not see your refund in your account in 5-10 business days please contact us at info@oharaslanding.com or call (860) 824-7583"
        );
      });
  };

  const CancelReservation = () => {
    const url = `https://us-central1-oharas-landing-a7318.cloudfunctions.net/cancelTwiceOrder?id=${reservationId}`;

    setLoading("Cancelling your reservation");
    setError("");
    fetch(url)
      .then((data) => {

        setReservation(data);
        setLoading("");
        setError("");
        SendEmails();
      })
      .catch((error) => {
        console.error("Error fetching order details:", error);
        setLoading("");
        setError(
          "Unable to cancel your reservation. Please contact us at info@oharaslanding.com or call (860) 824-7583 to cancel"
        );
      });
  };

  return (
    <>
      <button
        className=" text-darkBlue font-bold underline"
        type="button"
        onClick={() => {
          setReservation();
          setLoading("");
          setError("");
          setShowModal(true);
          setPaymentIntentId();
          setCancelled(false);
        }}
      >
        here
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none mx-4 focus:outline-none">
            <div className="relative w-auto mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between pt-5 pl-5 border-b border-solid border-blueGray-200 rounded-t">
                  {cancelled && !loading ? (
                    <div>
                      <h3 className="proxima700 text-darkBlue xs:text-2xl md:text-4xl  pr-16 font-semibold">
                        Your Reservation Has Been Cancelled
                      </h3>
                      <button
                        onClick={() => setShowModal(false)}
                        data-collapse-toggle="navbar-default"
                        type="button"
                        className="absolute  top-4 right-4 text-sm text-darkBlue  focus:outline-none"
                      >
                        <span className="sr-only">
                          Close reservation cancelation
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </button>
                      <div className="flex items-center text-center justify-center"></div>
                      <div className="relative flex-auto pr-5">
                        <p className="text-darkBlue text-sm leading-relaxed">
                          {`Reference #${reservationId}-${paymentIntentId}`}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h3 className="proxima700 text-darkBlue xs:text-2xl md:text-4xl font-semibold">
                        Cancel Your Reservation
                      </h3>
                      <button
                        onClick={() => setShowModal(false)}
                        data-collapse-toggle="navbar-default"
                        type="button"
                        className="absolute  top-4 right-4 text-sm text-darkBlue  focus:outline-none"
                      >
                        <span className="sr-only">
                          Close reservation cancelation
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </button>
                      <div className="relative flex-auto pr-5">
                        <p className="text-darkBlue text-sm leading-relaxed">
                          If you'd like to change the date of your reservation.
                          Please give us a call at (860) 824-7583 and we will be
                          happy to find a new date that works for you
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                {/*body*/}
                {!cancelled && (
                  <>
                    {loading !== "" && !cancelled && (
                      <div className="flex h-48 items-center text-center justify-center">
                        <div>
                          <div
                            role="status"
                            className="flex items-center justify-center"
                          >
                            <svg
                              aria-hidden="true"
                              class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-darkBlue"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>

                            <span class="sr-only">Loading...</span>
                          </div>
                          <div className="pt-4 proxima600">{loading}</div>
                        </div>
                      </div>
                    )}
                    {!reservation && loading === "" && (
                      <>
                        <div className="relative  p-5 flex-auto">
                          <p className="my-4 text-blueGray-500 xs: text-sm md:text-md leading-relaxed">
                            To cancel your reservation, locate your reservation
                            ID on the payment receipt you received attached to
                            your reservation confirmation email from O'Hara's
                            Landing.
                          </p>

                          <p className="my-4 text-blueGray-500 xs: text-sm md:text-md leading-relaxed">
                            Enter the code below:
                          </p>
                        </div>
                        <div>
                          <label
                            for="id"
                            className="inline px-3 mx-4 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            ID:
                          </label>
                          <input
                            type="text"
                            id="id"
                            onChange={(e) => {
                              setError("");
                              setReservationId(e.target.value);
                            }}
                            className="inline bg-gray-50 border mb-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-1/2 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="XXXXXXXXXXXXXXXXXXXX"
                            required
                          />
                        </div>
                        <PhotoProvider photoClosable maskClosable>
                          <div className="relative p-5 flex-auto">
                            <p className="my-4 text-blueGray-500 xs:text-sm md:text-md leading-relaxed">
                              Need help locating your reservation ID? Click{" "}
                              <span>
                                <PhotoView src={Receipt}>
                                  <span className="photoClosable underline cursor-pointer">
                                    here
                                  </span>
                                </PhotoView>
                              </span>
                              {" for an example"}
                            </p>
                          </div>
                        </PhotoProvider>
                      </>
                    )}
                    {reservation && loading === "" && (
                      <div className="px-5">
                        <div className="relative  pt-5 flex-auto">
                          <p className="my-4 text-darkBlue xs: text-sm md:text-md leading-relaxed">
                            {"We've located a reservation for: "}
                            {reservation.customerDetails.firstName}
                          </p>
                        </div>
                        <div className="border bg-gray-50 rounded pl-4">
                          <p className="my-4 xs:text-sm md:text-md  text-mediumBlue leading-relaxed">
                            Your Reservation:
                          </p>
                          <div className="relative  flex-auto">
                            <p className="my-4 text-darkBlue xs:text-md md:text-lg leading-relaxed">
                              {new Date(
                                reservation.items[0].startDate
                              ).toLocaleString("en-US")}
                            </p>
                          </div>
                          <div className="relative  flex-auto">
                            <p className="my-4 text-darkBlue xs:text-md md:text-lg leading-relaxed">
                              {reservation.items[0].name.en}
                            </p>
                          </div>
                        </div>
                        <div className=" flex-auto">
                          <p className="my-4 text-darkBlue xs: text-sm md:text-md leading-relaxed">
                            If this is correct, click below to cancel your
                            reservation. This cannot be undone.
                          </p>
                        </div>
                      </div>
                    )}
                    {Error && (
                      <div className="relative pl-5 pt-5 text-center flex-auto">
                        <p className=" text-red-700 xs:text-sm md:text-md leading-relaxed">
                          {error}
                        </p>
                      </div>
                    )}
                  </>
                )}
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  {!cancelled && (
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 xs:text-xs md:text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                  )}
                  <button
                    className="bg-darkBlue text-white disabled:bg-gray-300 disabled:text-gray-600 active:bg-darkBlue font-bold uppercase xs:text-xs md:text-sm px-6 py-3 rounded shadow hover:shadow-lg disabled:shadow-none outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    disabled={error != ""}
                    onClick={() => {
                      cancelled
                        ? setShowModal(false)
                        : reservation
                        ? IssueRefund()
                        : getReservation();
                    }}
                  >
                    {cancelled
                      ? "Done"
                      : reservation
                      ? "Cancel My Reservation"
                      : "Find My Reservation"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
