import React from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { ParallaxBanner } from "react-scroll-parallax";
import { Element } from "react-scroll";
import "react-photo-view/dist/react-photo-view.css";
import LakeView from "../images/OHarasLakeView.jpg";
import image1 from "../images/gallery/1.jpg";
import image2 from "../images/gallery/2.jpg";
import image3 from "../images/gallery/3.jpg";
import image4 from "../images/gallery/4.jpg";
import image5 from "../images/gallery/5.jpg";
import image6 from "../images/gallery/6.jpg";
import image7 from "../images/gallery/7.jpg";
import image8 from "../images/gallery/8.jpg";
import image9 from "../images/gallery/9.jpg";
import image10 from "../images/gallery/10.jpg";
import image11 from "../images/gallery/11.jpg";
import image12 from "../images/gallery/12.jpg";
import image13 from "../images/gallery/13.jpg";
import image14 from "../images/gallery/14.jpg";
import image15 from "../images/gallery/15.jpg";
import image16 from "../images/gallery/16.jpg";
const Gallery = () => {
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
  ];

  return (
    <Element name="gallery">
      <div className="h-auto">
        <PhotoProvider>
          <ParallaxBanner
            layers={[
              { image: LakeView, speed: -20 },
              {
                speed: -15,
              },
            ]}
            className="h-256"
          ></ParallaxBanner>
          <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-4 w-full gap-2 p-4 ">
            {images.map((image, index) => (
              <PhotoView key={index} src={image}>
                <img
                  src={image}
                  className="object-cover object-center w-full max-w-full rounded-lg"
                  alt=""
                />
              </PhotoView>
            ))}
          </div>
        </PhotoProvider>
      </div>
    </Element>
  );
};

export default Gallery;
