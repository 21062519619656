import React from "react";

import { ParallaxBanner } from "react-scroll-parallax";
import Mercury from "../images/Mercury-20-FourStroke_Mercury-Ocean-Runner_tubing_IMGL9050.jpg";
import InfoCard from "../components/InfoCard";
import { Typography } from "@material-tailwind/react";
import Service from "../images/noun-propeller-6549197.svg";
import Repairs from "../images/noun-repair-4272166.svg";
import Dock from "../images/noun-dock-1839587.svg";
import Winter from "../images/noun-winter-6927231.svg";
import { Element } from "react-scroll";
const SalesAndService = () => {
  return (
    <Element name="salesandservice" className="w-full">
      <div className=" relative w-full">
        <div className="absolute inset-0 sm:from-gray-900/75 flex items-center justify-center sm:bg-gradient-to-tr "></div>
        <iframe
          style={{ width: "100vw", height: "calc(100vw/1.77777777778)" }}
          src="https://www.youtube.com/embed/_TgULmWHr0s?playlist=_TgULmWHr0s&amp;controls=0&amp;showinfo=0&amp;rel=0&amp;autoplay=1&amp;loop=1&amp;mute=1"
          allow="autoplay;"
          frameborder="0"
          allowfullscreen=""
        ></iframe>

        <div className="relative bg-lightGray  border-lightBlue pt-12 h-1/3 xs:px-4 md:px-16">
          <div className="arizona xs:text-3xl text-darkBlue md:text-5xl">
            Everything you need for your boat. Experts on-site.
          </div>
          <div className="flex xs:justify-center md:justify-left xs:pb-8 md:pb-16 pt-8">
            <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 md:pb-24 w-full">
              <InfoCard
                title="Sales"
                text="Everything from pontoon boats to fishing boats, rowboats, speed boats and cabin cruisers. We proudly offer Mercury Marine and Starcraft Marine. We also have a selection of pre-owned boats and motors."
                textColor="text-darkBlue"
                image={Service}
              />

              <InfoCard
                title="Service"
                text="With over 100 years of combined experience, our certified technicians use the latest technology, and insist on first-rate and friendly service. A large inventory of parts is stocked on site."
                textColor="text-darkBlue"
                image={Repairs}
              />

              <InfoCard
                title="Dock Spaces"
                text="Boats up to 24' are accommodated. Please note that dock spaces are subject to availability and we do not have any available spaces for the 2024 season."
                textColor="text-darkBlue"
                image={Dock}
              />

              <InfoCard
                title="Off-Season"
                text="We will prepare and store your boat for the winter, and ready your boat for launch in the spring. We also service and pick up from other nearby lakes in Connecticut, New York and Massachusetts."
                textColor="text-darkBlue"
                image={Winter}
              />
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default SalesAndService;
