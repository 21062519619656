import React from "react";
import { Element } from "react-scroll";

const Map = () => {
  return (
    <Element name="map">
      <div className="relative w-screen border-t-2 border-lightBlue">
        <div id="map" />
        <div className="cursor-pointer">
          <a
            target="_blank"
            href="https://www.google.com/maps/dir//254+Twin+Lakes+Rd,+Salisbury,+CT+06068/@42.0358438,-73.3768587,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x89e77c0113117973:0x7e418a102d17f43a!2m2!1d-73.3751206!2d42.0363299!3e0?entry=ttu"
            className=" bg-black flex justify-center items-center px-12 py-3 text-sm font-bold text-white "
          >
            <div className=" w-auto">
              Get Directions
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4 inline ml-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </div>
          </a>
        </div>
      </div>
    </Element>
  );
};

export default Map;
