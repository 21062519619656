import React from "react";
import Services from "./Services";
import { Link } from "react-scroll";
import Logo from "../images/Marina-Logo-Fixed.svg";
import WeatherWidget from "../components/WeatherWidget";
const Header = () => {
  return (
    <section
      className={`relative bg-[url('./images/hero.jpg')] bg-cover bg-center bg-no-repeat`}
    >
      <div className="absolute inset-0 sm:from-darkBlue/40 flex  sm:bg-gradient-to-tr z-10 "></div>

      <div className="relative h-screen flex w-screen justify-center px-4 items-center z-20">
        <div className="block ">
          <div className="flex w-screen justify-center px-4 items-center">
            <img src={Logo} className="fill-lightGray xs:w-36 md:w-72" />
          </div>
          <div className="mt-6 w-full px-4 arizona text-lightGray text-center xs:text-4xl md:text-4xl">
            Twin Lakes, CT
          </div>
          <div className="mt-8 px-4 flex flex-wrap gap-4 justify-center ">
            <Link
              smooth={true}
              duration={500}
              to="rentals"
              className="cursor-pointer block w-full rounded bg-darkBlue px-12 py-3 text-sm font-bold text-lightGray shadow hover:bg-lightBlue xs:text-center hover:text-darkBlue sm:w-auto"
            >
              Rent a Boat
            </Link>

            <Link
              smooth={true}
              duration={500}
              to="map"
              className="block w-full rounded bg-lightGray px-12 py-3 text-sm font-bold text-darkBlue  hover:bg-lightBlue cursor-pointer shadow focus:outline-none xs:text-center sm:w-auto"
            >
              Get Directions
            </Link>
          </div>
        </div>
      </div>
    
    </section>
  );
};

export default Header;
