import React, { useEffect } from "react";
import { ParallaxBanner } from "react-scroll-parallax";
import RentalBoats from "../images/OHarasRentals.jpg";
import Wave from "react-wavify";
import { Element } from "react-scroll";
import { Link } from "react-router-dom";
import CancelModal from "../components/CancelModal";
const Rentals = () => {
  return (
    <div className="relat dive ">
      <div className="h-screen">
        <ParallaxBanner
          layers={[
            { image: RentalBoats, speed: -20 },
            {
              speed: -15,
            },
          ]}
          className="h-4/6"
        >
          <div className="absolute inset-0 sm:from-gray-900/75 flex items-center justify-center sm:bg-gradient-to-tr "></div>
        </ParallaxBanner>
        <Element name="rentals">
          <div className="relative bg-darkBlue flex justify-left border-lightBlue border-t-2 items-center py-16 xs:px-4 md:px-16">
            <div>
              <div className="proxima600 text-white pb-4">Boat rentals</div>
              <div className="arizona xs:text-3xl text-white md:text-5xl">
                Reserve your boat online today. Relax any time.
              </div>
            </div>

            <div className="absolute bottom-0 left-0 w-full z-10 h-36">
              <Wave
                fill="#87C9ED"
                paused={false}
                style={{ display: "flex", height: "100%" }}
                options={{
                  height: 100,
                  amplitude: 30,
                  speed: 0.15,
                  points: 3,
                }}
              ></Wave>
            </div>
          </div>
        </Element>
      </div>

      {/*<div className="twice-xl:h-[1250px] twice-lg:h-[1150px] twice-md:h-[1050px] twice-sm:h-[950px] twice-xs:h-[1750px] overflow-hidden">*/}

      <div className="text-black relative">
        <rentle-store scrolling="no" shop="oharaslanding" />
        <div className="absolute text-lightGray bottom-0 bg-lightGray w-full  ">
          <div className="h-120 xs:px-4 sm:px-8 md:px-16 xs:pt-8 sm:pt-16 text-darkBlue">
            <div className="proxima600 sm:text-md md:text-lg">
              Cancellation Policy
            </div>
            <div className="proxima400 xs:text-sm sm:text-md md:text-lg pt-2">
              We understand that plans can change. To accommodate this, we offer
              a flexible cancellation policy. You may cancel your appointment or
              reservation for any reason more than 24 hours in advance without
              penalty. Cancellations made less than 24 hours before the
              scheduled time will not be refunded.{" "}
              <span className="font-bold">
                {" "}
                Need to cancel a reservation? Click <CancelModal />
              </span>
            </div>
            <div className="proxima400 xs:text-sm md:text-lg pt-2">
              Going to be late? Give us a call at (860) 824-7583. If you are
              more than an hour late without notice we may not hold your
              reservation.
            </div>

            <div className="proxima600 xs:text-sm sm:text-sm md:text-lg pt-2 ">
              General Information
            </div>
            <div className="proxima400 xs:text-sm sm:text-sm md:text-lg pt-2">
              Rental rates are for the entire day. We do not offer partial day
              rates
            </div>
            <div className="proxima400 xs:text-sm sm:text-sm md:text-lg pt-2">
              No boating license required, just a valid driver's license to rent
              or drive the boat
            </div>
            <div className="proxima400 xs:text-sm sm:text-sm md:text-lg pt-2">
              Boat capacity is measured by total headcount (including children)
            </div>
            <div className="proxima400 xs:text-sm sm:text-sm md:text-lg pt-2">
              Prices do not include tax
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rentals;
