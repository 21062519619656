import "./App.css";
import "./index.css";
import "./images/hero.jpg";
import Home from "./Home";

import { getApp } from "firebase/app";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeFirestore, getFirestore } from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC8f4Z73gNp3zFWbp84pq91KL5qIlNPtZ0",
  authDomain: "oharas-landing-a7318.firebaseapp.com",
  projectId: "oharas-landing-a7318",
  storageBucket: "oharas-landing-a7318.appspot.com",
  messagingSenderId: "239950565003",
  appId: "1:239950565003:web:a6cf6d3a4465f7099e8e8e",
  measurementId: "G-78C47S6959"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


const functions = getFunctions(getApp());

export const db = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
})

const auth = getAuth();

/*signInAnonymously(auth)
  .then(() => {
    // Signed in..
    console.log('signInAnonymously')
  })
  .catch((error) => {
    var errorCode = error.code;
    var errorMessage = error.message;
    // ...
  });*/

//connectFunctionsEmulator(functions, "localhost", 5001);
export { functions };

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
