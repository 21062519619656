import React from "react";
import { Link } from "react-scroll";
const InfoCard = ({ title, text, image, linkTo, textColor, iconColor }) => {
  return (
    <div className="md:h-24">
      <img src={image} alt="Service Icon" className="h-16" />
      <div color="white" className={`${textColor} proxima600 mb-2 text-xl `}>
        {title}
      </div>

      <div className={`${textColor} proxima400 mb-2 md:h-16 text-md `}>
        {text}
      </div>

      {linkTo && (
        <div className=" flex items-end justify-start">
          <Link
            smooth={true}
            duration={500}
            to={linkTo}
            className="block px-3 py-2 rounded-md text-base font-bold text-lightBlue cursor-pointer"
          >
            <div className={` text-sunYellow proxima700 text-sm flex gap-2`}>
              Learn More
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="h-4 w-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                />
              </svg>
            </div>
          </Link>
        </div>
      )}
    </div>
  );
};

export default InfoCard;
