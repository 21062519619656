import React from "react";
import { ParallaxBanner } from "react-scroll-parallax";
import Owners from "../images/OHarasOwners.jpg";
import Wave from "react-wavify";
const FamilyOwned = () => {
  return (
    <div className="relative h-1/2">
      <ParallaxBanner
        style={{ height: "50vh" }}
        layers={[
          { image: Owners, speed: -20 },
          {
            speed: -15,
          },
        ]}
        className="h-1/2 opacity-3"
      >
        <div className="bg-mediumBlue flex items-center justify-center"></div>
        <div className="absolute inset-0 bg-darkBlue/65 flex items-center justify-center z-10">
          <div className="arizona md:py-32 xs:text-xl md:text-5xl">
            Family owned and operated since 1974
          </div>
        </div>
      </ParallaxBanner>
    </div>
  );
};

export default FamilyOwned;
