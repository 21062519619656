import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { weatherCodeFullDayIcons } from "../screens/Weather";

const WeatherWidgetHour = (forecast) => {
  const [hour] = useState(
    new Date(forecast?.forecast?.startTime).getHours() > 12
      ? new Date(forecast?.forecast?.startTime).getHours() - 12
      : new Date(forecast?.forecast?.startTime).getHours()
  );

  const textColor =
    new Date() > new Date(forecast?.forecast?.startTime)
      ? "text-gray-300"
      : "text-mediumBlue";
  return (
    <div
      class={clsx("flex flex-col bg-white rounded-xl w-full  p-4  ", textColor)}
    >
      <div class="text-sm font-semibold ">
        {hour < 8 || hour === 12 ? `${hour}pm` : `${hour}am`}
      </div>
      <div class="text-xl self-center inline-flex items-center justify-center rounded-lg h-16 ">
        <i
          className={`${
            weatherCodeFullDayIcons[forecast?.forecast?.values.weatherCode]
          } text-4xl mr-1 `}
        ></i>
      </div>

      <div className="flex font-medium justify-center my-2 text-center">
        <i className="wi wi-rain text-sm mr-1 "></i>

        <div className=" text-sm ">
          {forecast?.forecast?.values.precipitationProbability}%
        </div>
      </div>

      <div class="flex flex-row  justify-center gap-3">
        <div className="flex items-center justify-center ">
          <span className="items-center">
            <i className="wi wi-thermometer grow text-xl mr-2 "></i>
          </span>
          <span class="text-sm font-medium items-center mt-1 ">
            {Math.round(forecast?.forecast?.values.temperature)}°F
          </span>
        </div>
      </div>
    </div>
  );
};

export default WeatherWidgetHour;
